#news-carousel {
  .swiper-button-prev, .swiper-button-next, .swiper-pagination {
    opacity: 0;
    transition: opacity .25s; }
  .swiper-button-prev, .swiper-button-next {
    display: flex; }
  .swiper-pagination-bullet {
    margin: 0 1rem;
    background-color: #5F5F5F; } }

@media screen and (min-width: 660px) {
  #news-carousel {
    .swiper-pagination-bullet {
      padding: .7rem; } }

  @media screen and (min-width: 850px) {
    #news-carousel {
      .swiper-button-prev, .swiper-button-next {
        top: auto;
        bottom: 12%;
        padding: 3rem;
        color: #5F5F5F; }
      .swiper-button-prev {
        left: .5rem; }
      .swiper-button-next {
        right: .5rem; }
      .swiper-pagination-bullet {
        margin: .5rem 1rem; } }
    #news-carousel:hover {
      .swiper-button-prev, .swiper-button-next,.swiper-pagination {
        opacity: 1; } } } }
